import styled from 'styled-components';

// Container for the cards
export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;

  /* Desktop view */
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr); /* 4 cards per row for desktop */
    gap: 25px;
    width: 90%;
   display:flex;
   justify-content:center;
   alignitems:center;
    margin: 0 auto;
    
  }

  /* Tablet view */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row for tablet */
    gap: 20px;
    width: 90%;
   align-items:center;
   margin-left:20px;
   
  }

  /* Mobile view */
  @media (max-width: 450px) {
    grid-template-columns: 1fr; /* 1 card per row for mobile */
    gap: 15px;
    width: 80%;
  }
`;

// Individual card
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  height: 300px; /* Default card height */

  /* Increase height for desktop */
  @media (min-width: 1024px) {
    height: 375px; /* Adjust height for mobile */
    width: 375px; /* Adjust width for mobile */


}

  
`;

// Image at the top of the card
export const CardImage = styled.img`
  width: 100%;
  height: 50%;
  object-fit: contain;
  margin-bottom: 15px;
`;

// Title (h2) for the card
export const CardTitle = styled.h2`
  font-size: 20px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
`;

// Content (paragraph) at the bottom of the card
export const CardContent = styled.p`
  font-size: 16px;
  color: #555;
  text-align: center;
  line-height: 1.5;

  @media (min-width: 1024px) {
    width:60%;
  }
`;
