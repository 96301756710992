import styled from 'styled-components';

export const NameSectionContainer = styled.div`
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-around; /* Space between name section and image */
  width: 90%; /* Set a width for the entire section */
  margin: 20px auto; /* Center horizontally */
  padding: 20px; /* Add padding around the section */
  border-radius: 10px; /* Rounded corners */
  background-color: #fff; /* Background color for contrast */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);/* Shadow for a lifted effect */
  height: 70vh; /* Increased height for the section */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: flex-start; /* Align items to the start */
    height: auto; /* Allow height to adjust automatically */
    width: 95%;
    margin-left: 0; /* Align the container to the left */
    margin-right: auto;
    
   
       
  }

  @media (max-width: 431px) {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: flex-start; /* Align items to the start */
    height: auto; /* Allow height to adjust automatically */
    width: 90%;
    margin-left: 0; /* Align the container to the left */
    margin-right: auto;
    
   
       
  }
`;

export const NameContainer = styled.div`
  display:flex;
  flex-direction:column;
  width:35%;
  align-item:center;
  justify-content: space-between;
  margin-right: 20px; /* Space between name and image */
  height: 100%; /* Allow full height */

  @media (max-width: 768px) {
    margin-right: 0; /* Remove right margin on smaller screens */
    margin-bottom: 20px; /* Add bottom margin for spacing */
    width:92%;
  }
`;

export const NameTitle = styled.h2`
  font-size: 2rem; /* Font size for the title */
  color: #333; /* Title color */
  
  
  margin: 0 0 10px 0; /* Remove default margin and add bottom margin */
  @media (max-width: 768px) {
    width:80%;
    font-size: 1.5rem;
  }

  `;

export const SubHeading = styled.h3`
  font-size: 1.5rem; /* Font size for the subheading */
  color: #444; /* Subheading color */
  margin: 0 0 10px 0; /* Remove default margin and add bottom margin */

  @media (max-width: 768px) {
    width:98%;
    font-size:1.0rem;
  }
  `;

export const NameDescription = styled.p`
  font-size: 1rem; /* Font size for the description */
  color: #666; /* Description color */
  display:flex;
  flex-direction:column;
  line-height: 2; /* Line height for readability */
  
  
  
  ul {
    list-style-type: none;
    list-style-position: inside; /* Ensures list bullets are inside */
    margin-top: 20px;
    padding: 0;
    text-align: left; /* Align list text to the left */
  }

  li {
    margin-left: 0; /* Align list items to the leftmost side */
    font-weight: bold; /* Make the list items bold as per your original request */
    margin-bottom: 10px; /* Adds spacing between list items */
   


    img{
       width: 25px;
        height: 25px;
       margin-right:10px
    }
  }

  @media (max-width: 768px) {
    font-size:0.7rem;
    width:80%;

    li{


      img{
        width: 15px;
         height: 15px;
        margin-right:10px
     }
    }
  }

  `;

export const ImageContainer = styled.div`
  flex: 1; /* Allow this section to grow equally */
  max-width: 60%; /* Set a max width for the image container */
  height: 100%; /* Make sure it takes full height */
  display:flex;
  margin-left:0;
  @media (max-width: 768px) {
    max-width: 100%; /* Allow full width on smaller screens */
    height: auto; /* Allow height to adjust automatically */
  }
`;

export const StyledImage = styled.img`
  width: 100%; /* Make image responsive */
  height: 100%; /* Set height to 100% of the container */
  object-fit: contain; /* Ensure the image covers the area without overflow */
  border-radius: 10px; /* Rounded corners for the image */
  margin-left:0;
  
  @media (max-width: 768px) {
    max-width: 95%; /* Allow full width on smaller screens */
    height: auto; /* Allow height to adjust automatically */
  }
  
  @media (max-width: 380px) {
    max-width: 85%; /* Allow full width on smaller screens */
    height: auto; /* Allow height to adjust automatically */
  }

  `;
export const Button = styled.button`
  padding: 20px 20px; /* Add padding for button */
  background-color: #860a23; /* Button color */
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1rem; /* Font size */
  margin-top: 10px; /* Space above the button */
  width:40%;
  &:hover {
    background-color: DarkRed; /* Darken button on hover */
  }

  @media (max-width: 768px) {
    max-width: 35%; /* Full width on smaller screens */
    padding: 12px; /* Adjust padding */
  }

  @media (max-width: 431px) {
    max-width: 30%; /* Full width on smaller screens */
    padding: 12px; /* Adjust padding */
    font-size: 0.5rem;
  }
`;