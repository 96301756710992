import React from 'react';
import {
  INFO,
  BannerContainer,
  BannerBackground,
  Title,
  SubBox,
  RatingSection,
  RatingContainer,
  Rating,
  RatingLabel,
  VerticalLine,
  DownloadSection,
  DownloadLabel,
  LogoContainer,
  LogoImage
} from './bannersection.styles';

const BannerSection = () => {
  return (
    <BannerContainer>
      <BannerBackground style={{backgroundImage:"/bannersection.svg"}}>
        <Title>ENJOY THE APP</Title>
        <INFO>
        <SubBox>
          <h2>Best bus ticket booking app</h2>

          <p style={{ fontFamily: "'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'", fontSize: '16px' }}>
          Discover a world of convenience and entertainment with our app! Whether you're streaming, exploring new features, or managing your tasks, everything is designed to enhance your experience.
</p>
          
            
        

          <RatingSection>
            <RatingContainer>
              <Rating>4.8</Rating>
              <RatingLabel>App Store</RatingLabel>
            </RatingContainer>

            <VerticalLine />

            <RatingContainer>
              <Rating>4.7</Rating>
              <RatingLabel>Google Play</RatingLabel>
            </RatingContainer>
          </RatingSection>
        </SubBox>
        
        
          <DownloadSection>
            <DownloadLabel>Download from here</DownloadLabel>
            <LogoContainer>
             
              <LogoImage href="#"src="https://res.cloudinary.com/dxoejrovf/image/upload/v1727367361/pngwing.com_1_lsngbc.png" alt="Google Play" />
             
              <LogoImage href="#" src="https://res.cloudinary.com/dxoejrovf/image/upload/v1727367512/pngwing.com_dq3ebf_c_crop_w_910_h_300_hmtcsj.png" alt="App Store" />
            </LogoContainer>
          </DownloadSection>
        </INFO>
      </BannerBackground>
    </BannerContainer>
  );
};

export default BannerSection;
