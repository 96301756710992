import styled from 'styled-components';

export const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60vh;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative; /* Positioning for child elements */
  padding: 50px 0; /* Vertical padding for spacing */
`;

export const BannerBackground = styled.div`
  background-image: url('https://res.cloudinary.com/dxoejrovf/image/upload/v1727422572/img_1_kimzae.svg'); /* Replace with your banner image */
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the image */
  border-radius: 20px; /* Curved borders */
  padding: 40px; /* Padding inside the banner */
  color: white; /* Text color */
  position: relative; /* Positioning for inner elements */
  height: 60vh; 
 
  display: block;
  justify-content:evenly;
  width: 80%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
     /* Semi-transparent overlay */
    z-index: 0; /* Behind the content */
  }

  z-index: 1; /* Above the overlay */



  @media (max-width: 400px){
    height: 50vh; 

  }
`;

export const Title = styled.h1`
  font-size: 2.5rem; /* Font size for the title */
  margin-bottom: 20px; /* Space below the title */
  display: flex;
  color: #ffff;
 
  @media (max-width: 400px){
    font-size:1.2rem;

  }
`;

export const SubBox = styled.div`
  background: #ffff; /* White background with transparency */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding inside the sub-box */
  margin-bottom: 30px; /* Space below the sub-box */
  z-index: 1; /* Ensure it's above the background */
  width: 40%;
  height: 70%;

  h2 {
    margin: 0; /* Remove default margin */
    font-size: 1.5rem; /* Font size for the heading */
    color:black;
  }

  p {
    font-size: 16px; /* Font size for the description */
    font-weight:500;
    color: rgb(51, 51, 51);
  }

  @media (max-width: 768px) {



   
    h2 {
      margin: 0; /* Remove default margin */
      font-size: 1.2rem; /* Font size for the heading */
      color:black;
    }

    p {
      font-size: 0.7rem; /* Font size for the description */
      color: #333; /* Dark color for text */
    }
  }


  @media (max-width: 400px){

    height: 70%;
    h2 {
      margin: 0; /* Remove default margin */
      font-size: 0.9rem; /* Font size for the heading */
      color:black;
    }

    p {
      font-size: 0.5rem; /* Font size for the description */
      color: #333; /* Dark color for text */
    }

  }
`;

export const RatingSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 100px;
`;

export const RatingContainer = styled.div`
  flex: 1;
  text-align: center;
`;

export const Rating = styled.div`
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  @media (max-width: 768px) {
    margin-top:0px;
    font-size: 1.2rem;
  }
`;

export const RatingLabel = styled.p`
  font-size: 1rem;
  color: #666;

  @media (max-width: 768px) {
    margin-bottom:40px;
    font-size: 0.7rem;
  }
`;

export const VerticalLine = styled.div`
  margin-top:0;
  width: 3px;
  height: 50%;
  background-color: black;
  margin: 0 20px;


  @media (max-width: 768px) {
    margin-top:-5px;
    margin-bottom:15px;
    height: 50%;
  }

  
`;

export const INFO = styled.div`
  display: flex;
  height: 80%;
  flex-direction: row;
  /* Ensure it takes the full width of the container */
`;

export const DownloadSection = styled.div`
  text-align: center; /* Center content */
  width: 40%;

  @media (max-width: 768px) {

    margin-left:15px;
  }
 
  
`;

export const DownloadLabel = styled.p`
  font-size: 110%; /* Font size for download label */
  margin-bottom: 15px; /* Space below the label */
  color:#ffff;
  font-weight:bold;

  @media (max-width: 400px){
    font-size: 70%;
    
  }
`;

export const LogoContainer = styled.div`
  display: flex;
 
  flex-direction: column; /* Stack logos vertically */
  align-items:center; /* Center logos */
  justify-content:space-around;
  
`;

export const LogoImage = styled.img`
  width: 175px; /* Set logo width */
  margin: 5px 0; /* Space between logos */
  color:black;
 margin-bottom:"10px";
  background-white:black;
  @media (max-width: 768px) {
    width: 60%; /* Adjust logo width on smaller screens */
  }
`;
