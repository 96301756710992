import React from 'react';
import {Link} from 'react-router-dom';
import {
  NameSectionContainer,
  NameContainer,
  NameTitle,
  SubHeading, // Importing the new subheading styled component
  NameDescription,
  ImageContainer,
  StyledImage,Button
} from './sectiontwo.styles';

const Sectiontwo = () => {

  
  const icon1 = "https://res.cloudinary.com/dxoejrovf/image/upload/v1727430662/worker_cvzjye.png";
 const icon2 = "https://res.cloudinary.com/dxoejrovf/image/upload/v1727430535/smartphone_if6owi.png"

  const icon3 = "https://res.cloudinary.com/dxoejrovf/image/upload/v1727429341/operator_glrepp.png"
  return (
    <NameSectionContainer>
      <NameContainer>
        <NameTitle>NOW, GET MORE THAN JUST BUS TICKETS WITH MXBUS!</NameTitle>
        <SubHeading>Book Bus</SubHeading> {/* Added Subheading */}
        <NameDescription>
        Book Bus Tickets on mxbus simple & superfast booking process with no service fee + no payment gateway charge.
         
            <ul style={{fontWeight:"bold"}} >
             <li>
             <img src={`${icon1}`} alt="Icon"  />
             Authorised with Us</li>
             <li><img src={`${icon2}`} alt="Icon"  />Instant refunds on UPI payments</li>
             <li><img src={`${icon3}`} alt="Icon"  />Hassle-free customer support</li>

            </ul>
       
        </NameDescription>
       <Button>
        <Link to='/booking' style={{color:"white"}}>
        Book Bus Now
        </Link>
        </Button>
      </NameContainer>

      <ImageContainer>
        <StyledImage src="https://res.cloudinary.com/ddysynr5e/image/upload/c_crop,g_auto,h_800,w_800/qjtnqkgihy9lvg25zgbg.jpg" alt="Description Image" />
      </ImageContainer>
    </NameSectionContainer>
  );
};

export default Sectiontwo;
