import React from 'react';
import { CardContainer, Card, CardImage, CardContent, CardTitle } from './cardcomponent.styles';

const CardComponent = () => {
  const cardsData = [
    {
      image: 'https://res.cloudinary.com/dxoejrovf/image/upload/v1727434728/istockphoto-1173780314-612x612_a0j2zz.jpg',
      title: 'Happy Customers',
      content: '36 Million happy customers globally',
    },
    
    {
      image: 'https://res.cloudinary.com/dxoejrovf/image/upload/v1727439636/istockphoto-1202308955-612x612_tum2fv.jpg',
      title: 'Bus Companies',
      content: 'Over 5000 bus companies worldwide',
    },
    {
      image: 'https://res.cloudinary.com/dxoejrovf/image/upload/v1727435406/istockphoto-1396888743-612x612_lruspw.jpg',
      title: 'Tickets Booked',
      content: '200,000+ tickets booked everyday',
    },
  ];

  return (
    <CardContainer>
      {cardsData.map((card, index) => (
        <Card key={index}>
          <CardImage src={card.image} alt={`Card ${index + 1}`} />
          <CardTitle>{card.title}</CardTitle> {/* Add the title */}
          <CardContent>{card.content}</CardContent>
        </Card>
      ))}
    </CardContainer>
  );
};

export default CardComponent;
